import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import { Close, Logo } from 'component/new/icons';
import Tabs from 'component/new/Tabs/Tabs';
import useLongPress, { LongPressEvent } from '../../hooks/useLongPress';
import { TouchModeName } from '../input-mode-select/InputModeSelect.page';
import { useUser } from '../../hooks/auth/useUser';
import { getAudioPlayer } from '../../modules/audio/audioPlayer';
import { BreathingSession } from '../../modules/breathingSession';
import { AutomaticBreathingController } from '../../modules/AutomaticBreathingController';
import { getSearchPram } from '../../utils/getSearchPram';
import { preloadAudio } from '../../modules/audio/audioLoader';
import audioContext from '../../modules/audio/audioContext';
import { Box, Tooltip, Typography } from '@mui/material';
import Interaction from 'component/new/InteractionEffect/InteractionEffect';
import Nipple from 'component/Nipple';
import { THERAPY_MESSAGE } from './_constant';
import { getTouchModeName } from '../../utils/getTouchModeName';
import Dialog from 'component/new/Dialog/Dialog';
import { useNavigate } from 'react-router-dom';
import apiManager, { DateString } from '../../lib/api/apiManager';
import NoSleep from '@uriopass/nosleep.js';
import {
  After10BreathTooltip,
  EnteredUnconsciousTooltip,
  Under10NotificationTooltip,
} from './_components/TooltipTitle';
import { expoBridge, LAST_SESSION_DATE } from '../../modules/expo/expoBridge';
import moment from 'moment';
import { LAST_THERAPY_TIME } from '../_constants';

const nosleep = new NoSleep();

Howler.autoSuspend = false;

type TherapySessionProps = {
  onTouchStart: (params?: { isSlide: boolean }) => void;
  onTouchEnd: () => void;
  isActive: boolean;
  inputMode?: TouchModeName;
  handleDoubleClick: (event: LongPressEvent) => void;
};

const TherapySession = ({
  onTouchStart,
  onTouchEnd,
  isActive,
  inputMode = 'inhale-press',
  handleDoubleClick,
}: TherapySessionProps) => {
  const { onTouchStart: sanitizedTouchStart, onTouchEnd: sanitizedTouchEnd } =
    useLongPress((event) => onTouchStart(), onTouchEnd, handleDoubleClick, {
      delay: 180,
      preventDefault: true,
      inputMode,
    });

  return (
    <Box
      mt="44px"
      height="100%"
      onTouchStart={sanitizedTouchStart}
      onTouchEnd={
        inputMode !== 'inhale-slide-up'
          ? () => {
              sanitizedTouchEnd();
            }
          : undefined
      }
    >
      <Interaction isActive={isActive} />
      {inputMode === 'inhale-slide-up' ? (
        <Nipple
          onUp={() => onTouchStart()}
          onDown={() => sanitizedTouchEnd()}
        />
      ) : null}
    </Box>
  );
};

const tabItems = [
  { label: '의식모드', content: TherapySession },
  { label: '무의식모드', content: TherapySession },
];

const TherapyPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isPressed, setIsPressed] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [inhaleCount, setInhaleCount] = useState(0);
  const [quitModalOpen, setQuitModalOpen] = useState(false);
  const [sessionCompleteModalOpen, setSessionCompleteModalOpen] =
    useState(false);
  const [underTenAttempts, setUnderTenAttempts] = useState(false);

  const audioPlayerRef = useRef<ReturnType<typeof getAudioPlayer> | null>(null);
  const breathingSessionRef = useRef<BreathingSession | null>(null);
  const automaticBreathRef = useRef<AutomaticBreathingController | null>(null);
  const day = user.therapyDay || 1;

  const touchMode = getTouchModeName(user.touch_mode);

  const therapyTimeRef = useRef(Number(getSearchPram('time')));
  const instantSleep = useRef(getSearchPram('instant'));

  const endSession = () => {
    automaticBreathRef.current?.stop();
    audioPlayerRef.current?.stopAll();
    breathingSessionRef.current?.endSession();
    nosleep.disable();
  };

  const initializeAudio = useCallback(
    async (day: number, skipAudioLoad?: boolean) => {
      try {
        await audioContext.resumeAudioContext();
        if (!skipAudioLoad) {
          console.log(
            `Initializing audio for day ${day}, session length ${therapyTimeRef.current}`
          );
          await preloadAudio(therapyTimeRef.current, day);
        }

        audioPlayerRef.current = getAudioPlayer(therapyTimeRef.current);
        await audioPlayerRef.current.loadVolumeData(
          therapyTimeRef.current,
          day
        );
        audioPlayerRef.current.createSilentTrack();
      } catch (error) {
        console.error('오디오 초기화 중 오류가 발생했습니다:', error);
      }
    },
    []
  );

  const handleStart = useCallback(
    async (event: LongPressEvent, isSlide?: boolean) => {
      if (activeTab === 1) {
        return;
      }

      if (!inhaleCount) {
        breathingSessionRef.current?.startSession(() => {
          endSession();
          setSessionCompleteModalOpen(true);
          apiManager.notifySessionEnd(
            user.user_id || '',
            user.session_id || ''
          );
        });
        await nosleep.enable();
      }

      setInhaleCount((prev) => prev + 1);
      setIsPressed(true);
      audioPlayerRef.current?.playAllInhaleTracks();

      breathingSessionRef.current?.recordBreath(
        touchMode === 'exhale-press'
          ? BreathingSession.EXHALE_PHASE
          : BreathingSession.INHALE_PHASE,
        BreathingSession.CONSCIOUS_BREATH
      );
    },
    [touchMode, inhaleCount, user.user_id, user.session_id, activeTab]
  );

  const handleEnd = useCallback(() => {
    if (activeTab === 1) {
      return;
    }

    setIsPressed(false);
    audioPlayerRef.current?.playAllExhaleTracks();

    breathingSessionRef.current?.recordBreath(
      touchMode === 'exhale-press'
        ? BreathingSession.INHALE_PHASE
        : BreathingSession.EXHALE_PHASE,
      BreathingSession.CONSCIOUS_BREATH
    );
  }, [touchMode, activeTab]);

  const handleTabChange = (newValue: number) => {
    if (
      instantSleep.current !== 'true' &&
      ((user.touch_mode === 2 && inhaleCount < 9) ||
        (user.touch_mode === 1 && inhaleCount < 10) ||
        (user.touch_mode === 3 && inhaleCount < 10))
    ) {
      setUnderTenAttempts(true);
      return;
    }

    const isEnterUnconsciousMode = newValue === 1;

    if (isEnterUnconsciousMode) {
      // expo 앱 AsyncStorage 에 저장.
      expoBridge.setItem(
        LAST_SESSION_DATE,
        moment().format('YYYY-MM-DD HH:mm:ss')
      );

      localStorage.setItem(
        LAST_SESSION_DATE,
        moment().format('YYYY-MM-DD HH:mm:ss')
      );

      localStorage.setItem(
        LAST_THERAPY_TIME,
        therapyTimeRef.current.toLocaleString()
      );

      // 현재 상태에 따라 들숨 또는 날숨으로 시작
      const startWithInhale = !isPressed;

      const { avgInhale: localAvgInhale, avgExhale: localAvgExhale } =
        breathingSessionRef.current?.calculateAvg() || {
          avgInhale: 2000,
          avgExhale: 3000,
        };

      const avgInhale =
        instantSleep.current === 'true'
          ? user?.latest_avg_inhale || localAvgInhale
          : localAvgInhale;

      const avgExhale =
        instantSleep.current === 'true'
          ? user?.latest_avg_exhale || localAvgExhale
          : localAvgExhale;

      console.log({ startWithInhale, avgInhale, avgExhale });

      if (automaticBreathRef.current) {
        automaticBreathRef.current.updateAverages(avgInhale, avgExhale);

        automaticBreathRef.current.automaticBreathStart((isInhaling) => {
          setIsPressed(isInhaling);
          if (isInhaling) {
            audioPlayerRef.current?.playAllInhaleTracks();
          } else {
            audioPlayerRef.current?.playAllExhaleTracks();
          }
          breathingSessionRef.current?.recordBreath(
            isInhaling
              ? BreathingSession.INHALE_PHASE
              : BreathingSession.EXHALE_PHASE,
            BreathingSession.UNCONSCIOUS_BREATH
          );
        }, startWithInhale);

        setActiveTab(newValue);

        if (startWithInhale) {
          // 들숨으로 시작
          audioPlayerRef.current?.playAllInhaleTracks();
          breathingSessionRef.current?.recordBreath(
            BreathingSession.INHALE_PHASE,
            BreathingSession.UNCONSCIOUS_BREATH
          );
          setIsPressed(true);
        } else {
          // 현재 상태 유지 (날숨 상태로 시작)
          audioPlayerRef.current?.playAllExhaleTracks();
          breathingSessionRef.current?.recordBreath(
            BreathingSession.EXHALE_PHASE,
            BreathingSession.UNCONSCIOUS_BREATH
          );
        }

        if (user.user_id && user.session_id) {
          const isShorTherapy =
            therapyTimeRef.current === 5 || therapyTimeRef.current === 10;

          if (!isShorTherapy) {
            apiManager.increaseAccessCount(user.user_id, user.session_id);
          }
        }
      }
    } else {
      // 의식 모드로 전환
      automaticBreathRef.current?.stop();
      setIsPressed(false);
      setActiveTab(0);
    }
  };

  const handleDoubleClick = (event: LongPressEvent) => {
    if (
      instantSleep.current !== 'true' &&
      ((user.touch_mode === 2 && inhaleCount < 9) ||
        (user.touch_mode === 1 && inhaleCount < 10) ||
        (user.touch_mode === 3 && inhaleCount < 10))
    ) {
      setUnderTenAttempts(true);
      return;
    }

    // 더블 클릭 시 모든 진행 중인 동작을 중지
    setIsPressed(false);

    // 탭 전환
    handleTabChange(activeTab === 0 ? 1 : 0);
  };

  useEffect(() => {
    const prepareAudio = async () => {
      await initializeAudio(day);
    };

    if (!inhaleCount) {
      prepareAudio();
    }

    if (user.user_id && user.session_id && !breathingSessionRef.current) {
      breathingSessionRef.current = new BreathingSession(
        therapyTimeRef.current,
        user.user_id,
        user.session_id
      );

      try {
        automaticBreathRef.current = new AutomaticBreathingController(
          therapyTimeRef.current
        );
      } catch (error) {
        console.error('AutomaticBreathingController 초기화 오류:', error);
      }
    }

    if (instantSleep.current === 'true') {
      handleStart({} as LongPressEvent);
      handleTabChange(1);
    }

    return () => {
      audioPlayerRef.current?.reset();
      breathingSessionRef.current?.endSession();
      automaticBreathRef.current?.stop();
      audioPlayerRef.current = null;
      breathingSessionRef.current = null;
      automaticBreathRef.current = null;
    };
  }, [user.user_id, initializeAudio, user.session_id, instantSleep.current]);

  const afterTherapyNavigate = (therapyTime: number) => {
    const isShortTermTherapy = therapyTime === 5 || therapyTime === 10;

    if (isShortTermTherapy) {
      navigate('/', {
        replace: true,
      });
    } else {
      navigate(`/sound-review?time=${therapyTimeRef.current}`, {
        replace: true,
      });
    }
  };

  const isConsciousMode = activeTab === 0;
  const isUnconsciousMode = activeTab === 1;

  const { onScreen2, onScreen1, onScreenUnconscious } =
    THERAPY_MESSAGE[touchMode as TouchModeName];

  return (
    <>
      <PageContainer>
        <AppHeaderBar
          center={<Logo />}
          right={<Close onClick={() => setQuitModalOpen(true)} />}
        />
        <Box
          sx={{
            mt: '20px',
            overflow: 'hidden',
            height: '100%',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              left: '50%',
              top: '32px',
            }}
          >
            <Tooltip
              arrow
              title={
                underTenAttempts ? (
                  <Under10NotificationTooltip breathCount={inhaleCount} />
                ) : isConsciousMode && inhaleCount >= 10 ? (
                  <After10BreathTooltip />
                ) : isUnconsciousMode ? (
                  <EnteredUnconsciousTooltip />
                ) : (
                  ''
                )
              }
              placement="bottom"
              open={underTenAttempts || inhaleCount >= 10}
            >
              <Box sx={{ width: '1px', height: '1px' }}></Box>
            </Tooltip>
          </Box>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            items={tabItems}
            contentProps={{
              onTouchStart: () => handleStart({} as LongPressEvent),
              onTouchEnd: handleEnd,
              isActive: isPressed,
              inputMode: touchMode as TouchModeName,
              handleDoubleClick,
            }}
          />
        </Box>

        <Typography
          variant="headline2"
          sx={{
            width: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textShadow: '0px 0px 6px rgba(0, 0, 0, 0.40)',
            whiteSpace: 'pre-line',
            color: '#fff',
            opacity: 0.6,
            textAlign: 'center',
            zIndex: 0,
            pointerEvents: 'none',
          }}
        >
          {isConsciousMode ? (!isPressed ? onScreen1 : onScreen2) : ''}

          {isUnconsciousMode ? onScreenUnconscious : ''}
        </Typography>
      </PageContainer>
      <Dialog
        open={quitModalOpen}
        onClose={() => setQuitModalOpen(false)}
        onPrimaryButtonClick={() => {
          endSession();
          afterTherapyNavigate(therapyTimeRef.current);
        }}
        onSecondaryButtonClick={() => setQuitModalOpen(false)}
        title={'아직 소닉 테라피가\n 완료되지 않았어요!'}
        headline="소닉테라피로 다시 돌아가시겠어요?"
        primaryButtonText="종료할게요"
        secondaryButtonText="계속할래요"
      />
      <Dialog
        open={sessionCompleteModalOpen}
        onClose={() => setSessionCompleteModalOpen(false)}
        onPrimaryButtonClick={async () => {
          await initializeAudio(day);
          setInhaleCount(18);
          handleStart({} as LongPressEvent);
          handleTabChange(1);
          setSessionCompleteModalOpen(false);
        }}
        onSecondaryButtonClick={() => {
          endSession();
          breathingSessionRef.current = null;
          audioPlayerRef.current = null;
          automaticBreathRef.current = null;
          afterTherapyNavigate(therapyTimeRef.current);
        }}
        title="소닉테라피가 종료되었어요"
        headline={'다시 듣기를 눌러 무의식모드로\n 다시 진행할 수 있어요'}
        primaryButtonText="다시듣기"
        secondaryButtonText="종료하기"
      />
    </>
  );
};

export default TherapyPage;

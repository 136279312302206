import React, { FormEvent, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/icon/logo.svg';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import { Box, Snackbar, Typography } from '@mui/material';
import Input from 'component/new/Input';
import Button from 'component/new/Button/Button';
import Checkbox from 'component/new/Checkbox';
import { baseColors } from '../../styles/theme';
import { useUser } from '../../hooks/auth/useUser';
import { LOGIN_RESULT_CODE } from '../../lib/api/auth';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from 'component/new/Drawer/Drawer';
import { useDrawer } from '../../utils/ui/useDrawer';
import TermsAgreeForm from './_components/TermsAgreeForm';
import apiManager, { LoginResponse } from '../../lib/api/apiManager';
import { useLoginForm } from './hooks/useLoginForm';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import { expoBridge } from '../../modules/expo/expoBridge';

const SignInPage = () => {
  const { setUser } = useUser();
  const { id, password, autoLogin, error, setError, onInputChange } =
    useLoginForm();

  const [loginResponse, setLoginResponse] = useState<
    LoginResponse | undefined
  >();
  const navigate = useNavigate();
  const { open, openDrawer, closeDrawer } = useDrawer();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    localStorage.setItem('userId', id);

    if (!id || !password) {
      setError('아이디와 비밀번호를 입력해주세요.');
      return;
    }

    try {
      const response = await apiManager.login(id, password);
      if (response) {
        console.log({ response });
        setLoginResponse(response);
      }

      switch (response?.result_code) {
        case LOGIN_RESULT_CODE.SUCCESS:
          const access_count = response?.access_count || 0;
          const isUserFirstAccess = access_count === 0;

          if (isUserFirstAccess) {
            openDrawer();
            return;
          }

          completeLoginAfterTermsCheck(response);
          expoBridge.setItem('accessToken', response?.token);

          break;
        case LOGIN_RESULT_CODE.INVALID_CREDENTIALS:
          setError('ID와 비밀번호를 다시 한번 확인해 주세요.');
          break;

        case LOGIN_RESULT_CODE.NOT_ALLOWED_DATE:
          setError('접속가능 일자가 아닙니다.');
          break;

        default:
          setError('현재 서버에 접속할 수 없습니다. 관리자에게 문의해 주세요.');
      }
    } catch {
      setError('현재 서버에 접속할 수 없습니다. 관리자에게 문의해 주세요.');
    }
  };

  const completeLoginAfterTermsCheck = (
    loginAPIResponse: LoginResponse,
    pushAgreed?: boolean
  ) => {
    const accessToken = loginAPIResponse?.token || '';
    const userId = loginAPIResponse.user_id;

    setUser({
      ...loginAPIResponse,
      accessToken,
      push_yn: pushAgreed ? 'Y' : 'N',
      isAuthorized: true,
    });

    if (autoLogin) {
      expoBridge.setItem('accessToken', accessToken);
    }
    expoBridge.setItem('userId', userId);

    localStorage.setItem('accessToken', accessToken);
    // localStorage.setItem('userId', userId);

    navigate('/welcome', { replace: true });
  };

  const handleTermsAgreeSubmit = async (pushAgreed: boolean = false) => {
    if (!loginResponse) return;

    try {
      localStorage.setItem('accessToken', loginResponse.token);

      if (pushAgreed) {
        expoBridge.enablePushNotification({ enabled: true });

        setTimeout(() => {
          const pushToken = expoBridge.getPushToken();

          apiManager.updateUserInfo({
            userId: id,
            push_yn: pushAgreed ? 'Y' : 'N',
            ...(!loginResponse.remark ? { remark: pushToken } : {}),
          });
        }, 5000);

        expoBridge.scheduleNotification({
          title: '오늘 밤 편안한 수면을 위한 준비가 되셨나요?',
          body: '',
          hour: 22,
          minute: 0,
          repeat: true,
        });
      } else {
        await apiManager.updateUserInfo({
          userId: id,
          push_yn: pushAgreed ? 'Y' : 'N',
        });
      }

      completeLoginAfterTermsCheck(loginResponse, pushAgreed);
    } catch (error) {
      console.error('업데이트 유저 실패:', error);
    }

    closeDrawer();
  };

  return (
    <PageContainer>
      <AppHeaderBar center={<Logo />} />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: '35px',
        }}
      >
        <form
          onSubmit={onSubmit}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '38px' }}>
            <Input
              name="id"
              label="아이디"
              value={id}
              onChange={onInputChange}
            />
            <Input
              type="password"
              name="password"
              label="비밀번호"
              value={password}
              onChange={onInputChange}
            />
          </Box>

          <Box
            sx={{
              marginTop: '38px',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Checkbox
              name="autoLogin"
              checked={autoLogin}
              onChange={onInputChange}
            />
            <Typography color="#fff">자동 로그인</Typography>
          </Box>

          <Box sx={{ mt: 'auto' }}>
            <Button type="submit" buttonSize="large">
              로그인
            </Button>
          </Box>
        </form>
      </Box>

      <CustomDrawer
        open={open}
        onClose={openDrawer}
        onOpen={closeDrawer}
        sx={{
          height: '60%',
        }}
      >
        <TermsAgreeForm onSubmit={handleTermsAgreeSubmit} />
      </CustomDrawer>

      <Snackbar
        open={!!error}
        onClose={() => setError('')}
        message={error}
        autoHideDuration={2000}
        sx={{
          transform: 'translateY(-250%)',
          padding: '0 24px',
          '& .MuiSnackbarContent-root': {
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: baseColors.neutral.bw['black-alpha-25'],
          },
        }}
      />
    </PageContainer>
  );
};

export default SignInPage;

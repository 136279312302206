import React, { ReactElement, useEffect, useState } from 'react';
import { PageContainer } from '../../styles/globalStyled/PageContainer';
import AppHeaderBar from 'component/new/AppHeaderBar/AppHeaderBar';
import {
  ArrowRight2,
  CircleQuestion,
  Leaf,
  LeafLarge,
  LeftArrow,
  Logo,
  MoonStar,
  Sun,
  SunLarge,
} from 'component/new/icons';
import { Box, IconButton, Typography } from '@mui/material';
import theme from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { GridContainer, ModeContainer } from './TherapySelection.styles';
import Button from '../../component/new/Button/Button';
import Dialog from '../../component/new/Dialog/Dialog';
import { useUser } from '../../hooks/auth/useUser';
import apiManager from '../../lib/api/apiManager';
import moment from 'moment';

type SessionType = 'sleep' | 'wakingUp' | 'meditation';
type ExplanationDialogType = Exclude<SessionType, 'sleep'>;

type ModalContent = {
  [key in ExplanationDialogType]: {
    title: string;
    icon: ReactElement;
    message: string;
  };
};

const getModalContents = ({
  type,
  username,
}: {
  type: ExplanationDialogType;
  username?: string;
}) => {
  const modalContent: ModalContent = {
    wakingUp: {
      title: '기상 모드는?',
      icon: <SunLarge />,
      message:
        '몸을 깨우기 위한 5분간의 테라피에요\n' +
        `${username}님의 호흡에 맞춰\n` +
        '몸을 깨울 수 있는 사운드를 제공해요',
    },
    meditation: {
      title: '명상 모드는?',
      icon: <LeafLarge />,
      message:
        '몸을 깨우기 위한 10분간의 테라피에요\n' +
        `${username}님의 호흡에 맞춰\n` +
        '몸을 깨울 수 있는 사운드를 제공해요',
    },
  };

  return modalContent[type];
};

const TherapySelectionPage = () => {
  const navigate = useNavigate();
  const { generateSessionId, user } = useUser();
  const [hasTodaySurvey, setHasTodaySurvey] = useState(false);

  const [modeExplanationOpen, setModeExplanationOpen] = useState<{
    open: boolean;
    type: ExplanationDialogType;
  }>({
    open: false,
    type: 'wakingUp',
  });

  const [preSurveyDialog, setPreSurveyDialog] = useState<{
    open: boolean;
    onConfirmCallback?: () => void;
    onCancelCallback?: () => void;
  }>({
    open: false,
    onConfirmCallback: () => {},
    onCancelCallback: () => {},
  });

  const modeExplanationDialogHandle = {
    open: (type: ExplanationDialogType) =>
      setModeExplanationOpen({ open: true, type }),
    close: () =>
      setModeExplanationOpen({
        open: false,
        type: 'wakingUp',
      }),
  };

  const modeNavigateHandle = (time: '5' | '10' | '30' | '60') => {
    // if (time === '5' || time === '10') {
    generateSessionId();
    navigate(`/loading-therapy?time=${time}`);
    return;
    // }

    // setPreSurveyDialog({
    //   open: true,
    //   onConfirmCallback: () => navigate(`/pre-survey?time=${time}`),
    //   onCancelCallback: () => {
    //     generateSessionId();
    //     navigate(`/loading-therapy?time=${time}`);
    //   },
    // });
  };

  useEffect(() => {
    const getTodaySurvey = async () => {
      if (!!user.user_id) {
        const now = moment();
        const today = moment().startOf('day');
        const isBeforeNoon = now.hour() < 12;

        let startDate, endDate;

        if (isBeforeNoon) {
          // 현재 시간이 정오 이전이면 어제부터 오늘까지 조회
          startDate = moment(today).subtract(1, 'day');
          endDate = today;
        } else {
          // 현재 시간이 정오 이후면 오늘만 조회
          startDate = today;
          endDate = today;
        }

        const response = await apiManager.getUserSurvey(
          user.user_id,
          startDate,
          endDate
        );

        if (response && Object.keys(response).length > 0) {
          Object.values(response).forEach((survey) => {
            if (survey.pre) {
              setHasTodaySurvey(true);
            }
          });
        } else {
          console.log('데이터가 없습니다.');
        }
      }
    };

    getTodaySurvey();
  }, [user.user_id]);

  return (
    <PageContainer>
      <AppHeaderBar
        left={<LeftArrow onClick={() => navigate(-1)} />}
        center={<Logo />}
      />

      <Typography mt="20px" variant="title3" color="#fff">
        어떤 테라피를 시작할까요?
      </Typography>

      <GridContainer>
        <ModeContainer>
          <Box display="flex" gap="16px">
            <MoonStar />
            <Box>
              <Typography variant="title3" color="#fff">
                수면
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.semantic.font.textSecondary}
              >
                밤시간 추천
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="10px">
            <Button
              buttonShape="round"
              buttonSize="large"
              onClick={() => modeNavigateHandle('30')}
            >
              30분 시작하기
            </Button>
            <Button
              buttonShape="round"
              buttonSize="small"
              buttonStyle="secondary"
              onClick={() => modeNavigateHandle('60')}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.semantic.font.textSub}
              >
                60분 시작하기
              </Typography>
            </Button>
          </Box>
        </ModeContainer>
        <ModeContainer onClick={() => modeNavigateHandle('5')}>
          <Sun />

          <Box pl="7px">
            <Box display="flex" gap="6px">
              <Typography variant="title3" color="#fff" lineHeight={1}>
                기상
              </Typography>

              <IconButton
                sx={{ padding: 0, height: 'max-content' }}
                onClick={(e) => {
                  e.stopPropagation();
                  modeExplanationDialogHandle.open('wakingUp');
                }}
              >
                <CircleQuestion />
              </IconButton>

              <Box ml="auto">
                <ArrowRight2 />
              </Box>
            </Box>
            <Typography
              variant="subheadline"
              color={theme.palette.semantic.font.textSecondary}
              letterSpacing={-0.3}
            >
              5분 소요 · 아침시간용
            </Typography>
          </Box>
        </ModeContainer>

        <ModeContainer onClick={() => modeNavigateHandle('10')}>
          <Leaf />
          <Box pl="7px">
            <Box display="flex" gap="6px">
              <Typography variant="title3" color="#fff" lineHeight={1}>
                명상
              </Typography>

              <IconButton
                sx={{ padding: 0, height: 'max-content' }}
                onClick={(e) => {
                  e.stopPropagation();
                  modeExplanationDialogHandle.open('meditation');
                }}
              >
                <CircleQuestion />
              </IconButton>

              <Box ml="auto" height="max-content">
                <ArrowRight2 />
              </Box>
            </Box>
            <Typography
              variant="subheadline"
              color={theme.palette.semantic.font.textSecondary}
            >
              10분 소요 · 낮시간용
            </Typography>
          </Box>
        </ModeContainer>
      </GridContainer>

      {modeExplanationOpen.open && (
        <Dialog
          showCloseIcon
          open={modeExplanationOpen.open}
          onClose={modeExplanationDialogHandle.close}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="title2" color="#fff">
              {
                getModalContents({
                  type: modeExplanationOpen.type,
                }).title
              }
            </Typography>

            <Box
              sx={{
                width: '200px',
                height: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {
                getModalContents({
                  type: modeExplanationOpen.type,
                }).icon
              }
            </Box>

            <Typography
              variant="subheadline"
              whiteSpace="pre-line"
              color="#fff"
              textAlign="center"
            >
              {
                getModalContents({
                  type: modeExplanationOpen.type,
                  username: user?.name ?? '사용자',
                }).message
              }
            </Typography>
          </Box>
        </Dialog>
      )}

      <Dialog
        showCloseIcon
        open={preSurveyDialog.open}
        onClose={() => setPreSurveyDialog({ open: false })}
        primaryButtonText={hasTodaySurvey ? '새로 기록할래요' : '기록할게요'}
        secondaryButtonText="테라피를 바로 시작할래요"
        onCloseIconClick={() => setPreSurveyDialog({ open: false })}
        title={
          hasTodaySurvey
            ? '오늘 수면 환경 기록이 존재해요'
            : '테라피 시작 전,\n 오늘의 수면환경을 기록하시겠어요?'
        }
        headline={
          hasTodaySurvey
            ? `${user?.name}님`
            : `오늘의 수면 환경에 대해 기록하면 \n ${
                user?.name ?? '사용자'
              }님의 수면을 더 상세히 분석해 드려요`
        }
        onPrimaryButtonClick={preSurveyDialog.onConfirmCallback}
        onSecondaryButtonClick={preSurveyDialog.onCancelCallback}
      />
    </PageContainer>
  );
};

export default TherapySelectionPage;

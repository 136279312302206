import React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import { ReactComponent as CheckCircleWhiteSmall } from '../../assets/icon/checkbox/check-circle-small.svg';
import { ReactComponent as UncheckCircleSmall } from '../../assets/icon/checkbox/circle-unchecked-small.svg';
import { ReactComponent as CheckCircleDefaultLarge } from 'assets/icon/checkbox/check-circle-large-default.svg';
import { ReactComponent as CheckCircleDefaultSmall } from 'assets/icon/checkbox/check-circle-small-default.svg';

const iconMapper = {
  small: {
    circle: {
      white: CheckCircleWhiteSmall,
      default: CheckCircleDefaultSmall,
      teal: CheckCircleWhiteSmall,
    },
    square: {
      white: CheckCircleWhiteSmall,
      default: CheckCircleWhiteSmall,
      teal: CheckCircleWhiteSmall,
    },
  },
  large: {
    circle: {
      white: CheckCircleWhiteSmall,
      default: CheckCircleDefaultLarge,
      teal: CheckCircleWhiteSmall,
    },
    square: {
      white: CheckCircleWhiteSmall,
      default: CheckCircleWhiteSmall,
      teal: CheckCircleWhiteSmall,
    },
  },
};

const unCheckIconMapper = {
  small: {
    circle: UncheckCircleSmall,
    square: UncheckCircleSmall,
  },
  large: {
    circle: UncheckCircleSmall,
    square: UncheckCircleSmall,
  },
};

type CheckboxProps = MuiCheckboxProps & {
  size?: 'small' | 'large';
  iconColor?: 'white' | 'default' | 'teal';
  iconShape?: 'circle' | 'square';
};

const Checkbox = ({
  size = 'small',
  iconColor = 'white',
  iconShape = 'circle',
  ...props
}: CheckboxProps) => {
  const CheckIcon = iconMapper[size][iconShape][iconColor];
  const UnCheckIcon = unCheckIconMapper[size][iconShape];
  return (
    <MuiCheckbox
      sx={{
        padding: 0,
      }}
      icon={<UnCheckIcon />}
      checkedIcon={<CheckIcon />}
      {...props}
    />
  );
};

export default Checkbox;
export type { CheckboxProps };

import apiManager, { GetInfoResponse, LoginResponse } from 'lib/api/apiManager';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../component/new/Loader';
import { eventBus, TOKEN_EXPIRED } from '../modules/EventBus';
import { v4 as uuidv4 } from 'uuid';
import { getSearchPram } from '../utils/getSearchPram';
import { getTherapyDay } from '../utils/getTherapyDay';
import { logoutIfSessionExpired } from 'utils/auth/logoutIfSessionExpired';

type DefaultUser = {
  accessToken?: string;
  isAuthorized: boolean;
  session_id?: string;
  insetsTop?: string;
  therapyDay?: number;
} & Partial<GetInfoResponse>;

const DEFAULT_USER_STATUS: DefaultUser = {
  accessToken: '',
  isAuthorized: false,
  session_id: '',
};

type CreateContextProps = {
  login: (
    userId: string,
    password: string
  ) => Promise<LoginResponse | undefined> | void;
  logout: () => void;
  user: DefaultUser;
  setUser: (auth: DefaultUser) => void;
  isLoading: boolean;
  generateSessionId: () => string;
  therapyDay?: number;
};

const AuthContext = createContext<CreateContextProps>({
  user: DEFAULT_USER_STATUS,
  setUser: () => {},
  login: () => {},
  logout: () => {},
  generateSessionId: () => '',
  isLoading: true,
  therapyDay: 1,
});

const INSETS_TOP = 'insetsTop';

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<DefaultUser>(DEFAULT_USER_STATUS);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const insetsTop = getSearchPram(INSETS_TOP);

  const login = async (userId: string, password: string) => {
    try {
      return await apiManager.login(userId, password);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    setUser(DEFAULT_USER_STATUS);
    navigate('/signin');
  };

  const handleUser = (userInfo: DefaultUser) => {
    setUser(userInfo);
  };

  const generateSessionId = () => {
    const session_id = uuidv4();
    setUser((prevState) => ({ ...prevState, session_id }));
    return session_id;
  };

  const refreshUser = useCallback(async (userId: string) => {
    try {
      const userInfo = await apiManager.getUserInfo();

      if (userInfo) {
        setUser((prevState) => ({
          ...prevState,
          ...userInfo,
          user_id: userInfo.user_id,
          isAuthorized: true,
          therapyDay: getTherapyDay({
            access_count: userInfo?.access_count || 0,
            access_end: userInfo?.access_end,
            access_start: userInfo?.access_start,
            last_session_day: userInfo?.last_session_day,
          }),
        }));
      }
    } catch (error) {
      console.error('Failed to refresh user info:', error);
      setUser(DEFAULT_USER_STATUS);
    }
  }, []);

  useEffect(() => {
    const checkUserStatus = async () => {
      setIsLoading(true);
      const accessToken = localStorage.getItem('accessToken') || '';
      const userId = localStorage.getItem('userId') || '';

      if (location.pathname === '/qrcode') {
        setIsLoading(false);
        return;
      }

      if (accessToken && userId) {
        await refreshUser(userId);
        if (location.pathname === '/signin') {
          navigate('/');
        }
      } else {
        setUser(DEFAULT_USER_STATUS);
        if (location.pathname !== '/signin') {
          navigate('/signin');
        }
      }
      setIsLoading(false);
    };

    checkUserStatus();

    if (insetsTop) {
      setUser((prevState) => ({ ...prevState, insetsTop }));
    }

    eventBus.on(TOKEN_EXPIRED, logout);
  }, []);

  useEffect(() => {
    if (user && user.access_end) {
      logoutIfSessionExpired(user.access_end) && logout();
    }
  }, [user, location]);

  if (isLoading) {
    return <Loader />; // 로딩 중일 때 새로운 로더 컴포넌트 사용
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: handleUser,
        login,
        logout,
        isLoading,
        generateSessionId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext, DEFAULT_USER_STATUS };
